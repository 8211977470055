import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, CardActions, Checkbox, FormControlLabel, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import DialogAlertMain from "components/common/DialogAlertMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+0 000-000-0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});


const useStyles = makeStyles((theme) => ({
  fieldWidth: { width: "100%", },
  gridMargin: {
    marginTop: "10rem", marginBottom: "1rem",
    [theme.breakpoints.down("md")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("sm")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("xs")]: { marginTop: "8rem", marginBottom: "0rem", },
  },
  center: {
    textAlign: "center", color: "#35BFFF", fontSize: "2em", boxShadow: "5px",
    fontWeight: "300", marginTop: 0, fontFamily: '"Lato",sans-serif',
  },
  padding: {
    paddingTop: "5rem", paddingBottom: "5rem", width: "45vw", height: "auto", boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)", borderRadius: "6px",
    [theme.breakpoints.down("md")]: { width: "65vw", },
    [theme.breakpoints.down("sm")]: { width: "70vw", paddingLeft: "2rem", paddingRight: "2rem", },
    [theme.breakpoints.down("xs")]: { width: "90vw", paddingLeft: "0.5rem", paddingRight: "0.5rem", },
  },
  button: {
    width: "100%", backgroundColor: "#2cca5c", color: "white",
    "&:hover": { backgroundColor: "#2cca5c", color: "white", },
  },
}));

let initialValues = { email: "", password: "", };

let SignUpSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required !"),
  lastName: Yup.string()
    .max(20, "Maximum length is 20")
    .required("Last Name is required!"),
  firstName: Yup.string()
    .max(20, "Maximum length is 20")
    .required("First Name is required!"),
  password: Yup.string()
    .required('New Password is required!')
    .matches(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
      "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
    .min(8, "Must Contain 8 Characters"),
  confirmPassword: Yup.string()
    .required('Confirm Password is required!')
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
    phoneNumber: Yup.string().matches(
      /^\+[0-9]{1}\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
      "Please enter a valid Phone Number with country code"
    )
    .max(16, "Maximum length is 16 characters (including country code)")
    .required("Phone Number is required!"),
});

function Signup() {
  const axios = useAxiosPrivate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => { setOpen(false); };

  //eslint-disable-next-line
  const [zipCode, setZipCode] = useState("");

  let defaultZipCode = useSelector((state) => state.auth.defaultZipCode) || sessionStorage.getItem("defaultZipCode");

  useEffect(() => {
    setZipCode(defaultZipCode);
  }, [defaultZipCode]);

  const redirectTerms = () => { setOpen(true); };

  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [backendCall, setBackendCall] = useState(false);

  const handleChangeBox = (event) => { setChecked(event.target.checked); };

  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  let navigate = useNavigate();

  const submit = async (e) => {
    try {
      setBackendCall(true);
      const phoneNumber = e.phoneNumber;
      const cleanedPhoneNumber = phoneNumber.replace(/[\s-]/g, '');
      const { data } = await axios.post("/customer", {
        firstName: e.firstName, lastName: e.lastName, email: e.email, password:
        e.password, phoneNumber: cleanedPhoneNumber,
      });

      navigate("/signup/addlocation", { state: { id: data._id, from: "signup" }, });
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({ showAlert: true, severity: "error", message: "An account with this email address already exists!", });
      } else if (error.response.status === 402) {
        setAlert({ showAlert: true, severity: "error", message: "An account with this phone number already exists!", });
      } else if (error.response.status === 403) {
        setAlert({ showAlert: true, severity: "error", message: "Please check your email and password!", });
      } else {
        setAlert({ showAlert: true, severity: "error", message: error.response.data ?? "Server error!", });
      }
    } finally {
      setBackendCall(false);
    }
  };

  return (
    <Grid container className={classes.gridMargin} lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >
      <TermsAndConditions open={open} setOpen={setOpen} handleClose={handleClose} />
      <Card className={classes.padding} variant="outlined" sx={{ boxShadow: 3, }} >
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="h1" className={classes.center}> Create Your Free Account </Typography>
          <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
            <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} >
              {({ dirty, isValid, values }) => {
                return (
                  <Form>
                    <CardContent>
                      <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1} alignItems="center" justifyContent="center">
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Field className={classes.fieldWidth} name="firstName" label="First Name" component={TextField} variant="outlined"
                            fullWidth margin="dense" required={!open} ></Field>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Field className={classes.fieldWidth} name="lastName" label="Last Name" component={TextField} variant="outlined" fullWidth
                            margin="dense" required={!open} ></Field>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Field className={classes.fieldWidth} name="email" label="Email" component={TextField} variant="outlined"
                          fullWidth margin="dense" required={!open} ></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Field className={classes.fieldWidth} name="password" label="Password" component={TextField} variant="outlined"
                          fullWidth margin="dense" type="password" required={!open} ></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Field className={classes.fieldWidth} name="confirmPassword" label="Confirm Password" component={TextField}
                          variant="outlined" fullWidth margin="dense" type="password" required={!open} ></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                      <Field className={classes.fieldWidth} name="phoneNumber" label="Phone Number" component={TextField}   
                        InputProps={{
                      inputComponent: TextMaskCustom,
                        }} variant="outlined"
                          fullWidth margin="dense" required={!open} ></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <FormControlLabel
                          control={<Checkbox color="primary" checked={checked} onChange={handleChangeBox}
                            inputProps={{ "aria-label": "controlled", }} />}
                          label={
                            <div>
                              <span>I have read and agree to the </span>
                              <button style={{
                                border: "none", fontSize: "1rem", cursor: "pointer", color: "blue",
                                backgroundColor: "rgba(0, 0, 0, 0)",
                              }}
                                onClick={redirectTerms}> terms and conditions </button>
                            </div>} />
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <LoadingButton loading={backendCall} variant="contained" className={classes.button}
                          disabled={!dirty || !isValid || !checked} type="submit" size="large" > Continue </LoadingButton>
                      </Grid>
                    </CardActions>
                  </Form>);
              }}
            </Formik>
          </Grid>
        </Grid>
      </Card>
      <Grid container alignItems="center" justifyContent="center" item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }}>
        {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
          <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
      </Grid>
    </Grid>
  );
}

export default Signup;
