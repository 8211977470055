import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { TextField } from "formik-material-ui";
// import { styled } from "@mui/material/styles";

import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import { setDefaultAddress } from "../../../store/actions/authActions";
import { IMaskInput } from 'react-imask';
import { useDispatch } from "react-redux";
import DialogAlertMain from "components/common/DialogAlertMain";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+0 000-000-0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});


export default function AccountDetails({ classes, setLocation }) {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  let id = useSelector((state) => state.auth.id);
  const navigate = useNavigate();
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  let accountDetails = Yup.object().shape({
    email: Yup.string().email().required("Email is required!"),
    lastName: Yup.string()
      .max(20, "Maximum length is 20")
      .required("Last Name is required!"),
    firstName: Yup.string()
      .max(20, "Maximum length is 20")
      .required("First Name is required!"),
      phoneNumber: Yup.string().matches(
        /^\+[0-9]{1}\s[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
        "Please enter a valid Phone Number with country code"
      ),
  });

  const submit = async (e) => {

    const sendPayload = {
      email: e.email,
      firstName: e.firstName,
      lastName: e.lastName,
      phoneNumber: '+'+e.phoneNumber.replace(/[^\w]/g, ""),
    }

    try {
      setBackendCall(true);
      await axios.put("/customer", sendPayload);
      getData();
      setBackendCall(false);
    } catch (error) {
      setBackendCall(false);
      getData()
      if (error.response.status === 401) {
        setAlert({ showAlert: true, severity: "error", message: "An account with this email address already exists!", });
      } else if (error.response.status === 402) {
        setAlert({ showAlert: true, severity: "error", message: "An account with this phone number already exists!", });
      }
    }
  };

  const getData = async () => {
    try {
      setBackendCall(true);
      const { data } = await axios.get(`/customer/${id}`);

      setInitialValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });
      // save to the session storage
      const getDefaultLocation = data.location.find(item => item.isDefault === true ? item.address : null);
      sessionStorage.setItem("firstName", data.firstName);
      sessionStorage.setItem("lastName", data.lastName);
      sessionStorage.setItem("phone", data.phoneNumber);

      sessionStorage.setItem("defaultAddress", getDefaultLocation.address);
      dispatch(setDefaultAddress(getDefaultLocation.address));
      setLocation(data.location);
      setBackendCall(false);
    } catch (error) {
      setBackendCall(false);
    }
  };

  useEffect(() => {
    getData();
    /* eslint-disable */
  }, []);

  return (
    <Grid item xs={12} md={12} lg={12} className={classes.header}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h4" className={classes.headerTypo}>
          Account Details
        </Typography>
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={accountDetails}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid spacing={1} item xs={12} md={12} lg={12}>
                <Grid item>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={TextField}
                    variant="filled"
                    fullWidth
                    margin="dense"
                  ></Field>
                </Grid>

                <Grid item className={classes.fields}>
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={TextField}
                    variant="filled"
                    fullWidth
                    margin="dense"
                  ></Field>
                </Grid>

                <Grid item className={classes.fields}>
                  <Field
                    name="email"
                    label="Email"
                    component={TextField}
                    variant="filled"
                    fullWidth
                    margin="dense"
                  ></Field>
                </Grid>

                <Grid item className={classes.fields}>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    component={TextField}
                    variant="filled"
                    fullWidth
                    margin="dense"

                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  ></Field>
                </Grid>
              </Grid>
              <Grid container item alignItems="center" justifyContent="center">
                <Grid
                  item
                  xs={"auto"}
                  md={"auto"}
                  lg={"auto"}
                  className={classes.fields}
                  style={{ marginBottom: "1rem" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate("/changepassword")}
                    startIcon={<LockOpenIcon />}
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>

              <Grid container justifyContent="center">
                <Grid item>
                  <LoadingButton
                    loading={backendCall}
                    type="submit"
                    sx={{
                      backgroundColor: '#2cca5c',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#2cca5c',
                        color: 'white',
                      },
                    }}
                    variant='contained'
                    disabled={!dirty || !isValid}
                    size='large'
                  >
                    Save!
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Grid container alignItems="center" justifyContent="center" item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }}>
        {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
          <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
      </Grid>
    </Grid>
  );
}
