import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FilledInput from "@mui/material/FilledInput";
import { useDispatch, useSelector } from "react-redux";
import { setRewards, setPromo } from "../../../store/actions/orderAction";

const useStyles = makeStyles((theme) => ({
  containPromo: { marginBottom: "1.5rem", },
  tableCell: { "&:hover": { backgroundColor: "#e4eaf5", cursor: "pointer", }, },
  couponContainer: { width: "35%", marginTop: "0.3rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", paddingLeft: "0.7rem",
    paddingRight: "0.7rem", backgroundColor: "#EEEEEE",
    [theme.breakpoints.down(1199)]: { width: "58%", paddingLeft: "0.8rem", },
    [theme.breakpoints.down(1000)]: { width: "60%", paddingRight: "0.8rem", },
    [theme.breakpoints.down(960)]: { width: "40%", paddingRight: "0.8rem", },
    [theme.breakpoints.down(860)]: { width: "50%", },
    [theme.breakpoints.down(696)]: { width: "60%", },
    [theme.breakpoints.down(600)]: { width: "30%", },
    [theme.breakpoints.down(520)]: { width: "40%", },
    [theme.breakpoints.down(440)]: { width: "50%", },
    [theme.breakpoints.down(440)]: { width: "43%", },
  },
  couponStyle: { textAlign: "center", paddingTop: "0.7rem", paddingBottom: "0.7rem",
    [theme.breakpoints.down(1199)]: { maxWidth: "90%", },
    [theme.breakpoints.down(1000)]: { maxWidth: "100%", },
    borderRight: "0.15rem dashed #FF6200",
    backgroundImage: "radial-gradient(rgb(255, 255, 255) 50%, rgba(255, 98, 0, 1) 53%)",
    backgroundSize: "13px 7px", backgroundPosition: "-8px 1px", backgroundRepeat: "repeat-y", boxShadow: "none",
    fontSize: "1.1rem", fontWeight: " 700", backgroundColor: "#ffeee4", },
  promoContainer: { width: "35%", marginTop: "0.3rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", paddingLeft: "0.7rem",
    paddingRight: "0.7rem", backgroundColor: "#EEEEEE",
    [theme.breakpoints.down(1199)]: { width: "58%", paddingLeft: "0.8rem", },
    [theme.breakpoints.down(1000)]: { width: "60%", paddingRight: "0.8rem", },
    [theme.breakpoints.down(960)]: { width: "40%", paddingRight: "0.8rem", },
    [theme.breakpoints.down(860)]: { width: "50%", },
    [theme.breakpoints.down(696)]: { width: "60%", },
    [theme.breakpoints.down(600)]: { width: "30%", },
    [theme.breakpoints.down(520)]: { width: "40%", },
    [theme.breakpoints.down(440)]: { width: "50%", },
    [theme.breakpoints.down(440)]: { width: "43%", }, },
  promoStyle: { textAlign: "center", paddingTop: "0.4rem", paddingBottom: "0.4rem",
    [theme.breakpoints.down(1199)]: { maxWidth: "90%", },
    [theme.breakpoints.down(1000)]: { maxWidth: "100%", },
    border: "0.15rem dashed #64c2ed", backgroundSize: "13px 7px", backgroundPosition: "-8px 1px",
    backgroundRepeat: "repeat-y", boxShadow: "none", fontSize: "1.3rem", fontWeight: " 400", backgroundColor: "#d7f1ff", },
}));

export default function BasicSelect({ rewards, getPromoCode, promoData, isListEmpty, isWashDryFoldServiceSelected, isSpecialCareItemsEmpty, setAlert, isWashDataLoading, promoUsed, setPromoUsed}) {

  const classes = useStyles();
  let dispatch = useDispatch();
  const promo = useSelector((state) => state.order?.promo);
  const coupon = useSelector((state) => state.order?.rewards?.couponVal?.item);
  const state = useSelector((state) => state);
  const [couponValue, setCouponValue] = useState("");
  const [promoValue, setPromoValue] = useState("");
  const [dropDownmnu, setDropDown] = useState(false);
  const [hideText, setHideText] = useState(false);

  const onChangePromo = (event) => { 
    setPromoValue(event.target.value.split(" ").join("")); 
    sessionStorage.setItem('promoValue',JSON.stringify(event.target.value.split(" ").join("")));
  };

  useEffect(() => {
    if(JSON.parse(sessionStorage.getItem('promoValue')) === "" && JSON.parse(sessionStorage.getItem('setRewards'))?.couponVal !== ''){
      setCouponValue(sessionStorage.getItem('couponValue'));
      let coupon = JSON.parse(sessionStorage.getItem('setRewards'));
      onSearch(coupon?.couponVal?.couponType, coupon?.couponVal?.itemValue, coupon?.couponVal?.couponValue, coupon?.couponVal?.item)
    }
    else if(JSON.parse(sessionStorage.getItem('promoValue')) !== ""){ 
      setPromoValue(JSON.parse(sessionStorage.getItem('promoValue')))
      onSearch(JSON.parse(sessionStorage.getItem('promoValue')))
    }
  },[])

  const setServiceAvailability = (rewards) => {

    if (rewards) {
      const services = rewards.services;

      if(services.includes("Wash/ Dry/ Fold") && !isWashDryFoldServiceSelected && !(services.includes("Dry Cleaning") || services.includes("Tailoring & Alterations"))){
        setAlert({
          showAlert: true,
          severity: "error",
          message: "This promotion is not applicable to this service!",
        })
        clearBtn();
      }
  
      if((services.includes("Dry Cleaning") || services.includes("Tailoring & Alterations")) && isSpecialCareItemsEmpty && !services.includes("Wash/ Dry/ Fold")){
        setAlert({
          showAlert: true,
          severity: "error",
          message: "This promotion is not applicable to this service!",
        })
        clearBtn();
      }
    }
  }

  useEffect(() => {

    if(!(!isWashDryFoldServiceSelected && isSpecialCareItemsEmpty) && !isWashDataLoading){
      if(promo && promo?.id) setServiceAvailability(promo)
      if(coupon && coupon?.id) setServiceAvailability(coupon)
    }

  },[promo,coupon])


  // show avilable Service for promocodes
  const onSearch = (couponType, couponValue, itemValue, item) => {

    setCouponValue(couponValue);
    sessionStorage.setItem('couponValue',couponValue);

    setHideText(true);

    !couponValue && getPromoCode(JSON.parse(sessionStorage.getItem('promoValue')));

    if (couponValue && couponValue !== "") {
       dispatch(setRewards({ couponVal: { couponType: couponType, couponValue: itemValue, item, itemValue: couponValue }, promoVal: "", }) ); 
       dispatch(setPromo(null));
       sessionStorage.setItem('setRewards',JSON.stringify({ couponVal: { couponType: couponType, couponValue: itemValue, item, itemValue: couponValue }, promoVal: "", }));
       sessionStorage.setItem('promoValue',JSON.stringify(""));
    } else if (promo) { 
      dispatch(setRewards({ promoVal: {...promo, promoValue}, couponVal: "" })); 
    }
  };

  useEffect(() => {

    if(isListEmpty){
      clearBtn();
    }

  },[isListEmpty])

  useEffect(() => {
    if(promoUsed){
      clearBtn();
      setPromoUsed(false)
    }
  },[promoUsed])

  const clearBtn = () => {
    setHideText(false);
    setCouponValue("");
    setPromoValue("");
    dispatch(setRewards({ couponVal: "", promoVal: "" }));
    dispatch(setPromo(""));
    setDropDown(false);
    sessionStorage.setItem('couponValue',"");
    sessionStorage.setItem('setRewards',JSON.stringify({couponVal: "", promoVal: ""}));
    sessionStorage.setItem('promoValue',JSON.stringify(""));
  };
  const handleBlur = () => {
    setTimeout(()=> {setDropDown(false)} , 300 )
  };
  return (
    <div>
      <div className={classes.containPromo}>
        <Stack direction="row">
          {!hideText ? (
            <FilledInput data-testid="addPromoCode" type="text" placeholder="Add your code" sx={{ height: "3rem" }} value={promoValue}
              onChange={onChangePromo} variant="standard" onClick={() => setDropDown(true)} onBlur={handleBlur}/>
          ) : ( <div className={ couponValue ? `${classes.couponContainer}` : `${classes.promoContainer}` } >
              <div className={ couponValue ? `${classes.couponStyle}` : `${classes.promoStyle}` } >
              {couponValue ? `${couponValue}` : `${promoValue}`}
              </div>
            </div> )}
          {!hideText ? ( couponValue || promoValue ? (
              <Button sx={{ marginTop: "0.5rem", marginLeft: "1rem", height: "20%", width: "20%", }}
                onClick={() => onSearch(promoValue)} variant="text" > Apply </Button> ) : ( "" )
          ) : (<Button sx={{ marginTop: "0.5rem", marginLeft: "1rem", height: "20%", width: "20%", fontSize: "0.9rem",
            textTransform: "capitalize", }} onClick={clearBtn} variant="text" > Remove </Button> )}
        </Stack>
        {/* show availbe service for promo codes*/}
        {/* {!couponValue && hideText && promoServices} */}
        {dropDownmnu && !couponValue && !promoValue && (
          <div style={{ maxWidth: "15rem", maxHeight: 130, overflow: 'auto' }}>
            {rewards.slice(0, 10).map((item, i) => (
              <div>
                <TableContainer data-testid="selectCoupon" onClick={() => onSearch( item?.discountType,
                      `${item?.discountType === "percentage" ? `${item?.discountValue}%` : `$${item?.discountValue}` } off`,
                      item.discountValue, item ) } key={i} component={Paper} sx={{ maxWidth: "13.5rem" }} >
                  <Table>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <div style={{ fontSize: "1.1rem", fontWeight: "600", color: "#25C268", }} >
                          <h4> {`${item?.discountType === "percentage" ? `${item?.discountValue}%` : `$${item?.discountValue}` } off`} </h4>
                        </div>
                        <div> <p style={{ fontSize: "0.73rem", fontWeight: "300" }}> {`Can use for ${item?.services.join(", ")}`} </p> </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
